<template>
	<div
		v-if="meet"
		class="row-format centered"
		:style="`${inFrame ? '' : 'min-height: 100vh'}; font-family: '${font}'; font-size: ${meet.pageLayout.fontSize + 'px'}`"
	>
		<v-container :class="`${inFrame ? 'main-wrapper-in-frame' : 'main-wrapper'}`">
			<v-row v-if="!confirmationPage && !cancelPage">
				<v-col
					sm="5"
					cols="12"
					:style="{ backgroundColor: meet.pageLayout.backgroundColor, borderRight: '1px solid var(--v-gray_30-base)' }"
				>
					<meeting-info
						class=""
						:meet="meet"
						:confirmed-time="confirmedTime"
						:action="action"
						:token-map="tokenMap"
						:is-reschedule="!!this.id"
						:selected-location="selectedLocation"
						@select-location="selectedLocation = $event"
					></meeting-info>
				</v-col>
				<v-col sm="7" cols="12" :style="{ backgroundColor: meet.pageLayout.backgroundMainColor }">
					<meeting-schedule
						v-show="!confirmedTime || action === 'change'"
						:meet="meet"
						:start="start"
						:meeting-service="meetingService"
						:conflict-check="conflictCheck"
						@time-confirmed="confirmTime"
					></meeting-schedule>
					<meeting-form
						ref="MeetingForm"
						:meet="meet"
						v-show="confirmedTime && action !== 'change'"
						@back="unConfirmTime"
						@form-result="handleFormResult"
						:account-key="accountKey"
						:token-map="tokenMap"
						:preview="preview"
						:default-contact="defaultContact"
						:meeeting-service="meetingService"
						:stripe="stripe"
						:payment-intent-key="paymentIntentKey"
						:selected-location="selectedLocation"
						@stripe-payment-intent="setStripePaymentIntent($event)"
					></meeting-form>
				</v-col>
			</v-row>
			<v-row v-else-if="cancelPage" :style="`background-color: ${meet.pageLayout.backgroundMainColor}`">
				<v-col cols="12" align="center">
					<meeting-cancel
						v-if="confirmedMeeting"
						:meet="meet"
						:token-map="tokenMap"
						:confirmed-meeting="confirmedMeeting"
						:pod-url="podUrl"
						@error="handleError($event)"
					></meeting-cancel>
				</v-col>
			</v-row>
			<v-row v-else :style="`background-color: ${meet.pageLayout.backgroundMainColor}`">
				<v-col cols="12" align="center">
					<meeting-confirmation
						:meet="meet"
						:token-map="tokenMap"
						:confirmed-meeting="confirmedMeeting"
						:pod-url="podUrl"
						:action="action"
					></meeting-confirmation>
				</v-col>
			</v-row>
		</v-container>
		<v-snackbar v-model="showError" top color="gray_80" :timeout="10000">
			{{ errorText }}
			<template v-slot:action="{ attrs }">
				<v-btn color="error" text v-bind="attrs" @click="showError = false"> OK </v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
	import PublicMeetingService from '@/modules/meetings/PublicMeetingService';
	import { DateTime, Settings } from 'luxon';
	import MeetingInfo from '@/modules/meetings/MeetingInfo';
	import MeetingSchedule from '@/modules/meetings/MeetingSchedule';
	import MeetingForm from '@/modules/meetings/MeetingForm';
	import MeetingConfirmation from '@/modules/meetings/MeetingConfirmation';
	import MeetingCancel from '@/modules/meetings/MeetingCancel';
	import WebFontLoader from 'webfontloader';
	import { Base64 } from 'js-base64';

	export default {
		name: 'MeetingRenderer',

		props: ['podUrl', 'accountKey', 'meetingId', 'customFonts'],

		components: { MeetingConfirmation, MeetingForm, MeetingSchedule, MeetingInfo, MeetingCancel },

		data: function () {
			return {
				meetingService: new PublicMeetingService(this.podUrl),
				start: DateTime.now(),
				end: DateTime.now().endOf('month'),
				meet: null,
				confirmedTime: null,
				confirmationPage: false,
				confirmedMeeting: null,
				cancelPage: false,
				selectedLocation: null,
				font: null,
				showError: false,
				errorText: null,
				defaultContact: null,
				stripe: null,
				paymentIntentKey: null,
				stripePaymentIntent: null,
			};
		},

		mounted() {
			this.paymentIntentKey = 'MEET_PAYMENT_INTENT_' + this.accountKey + '_' + this.meetingId;

			let urlParams = new URLSearchParams(window.location.search);

			if (urlParams.get('knownUser') === 'true') {
				this.defaultContact = {
					firstName: urlParams.get('firstName'),
					lastName: urlParams.get('lastName'),
					email: urlParams.get('email'),
					phone: urlParams.get('phone'),
				};
			}

			if (this.id) {
				this.getExistingMeeting();
			} else {
				this.getMeetingInfo();
			}

			if (this.action === 'cancel') {
				this.cancelPage = true;
			}
		},

		beforeDestroy() {},

		methods: {
			getExistingMeeting: function () {
				let start = null;
				let end = null;

				if (this.action === 'change') {
					start = this.start.toISODate();
					end = this.end.toISODate();
				}

				this.meetingService
					.getExistingMeeting(this.id, start, end)
					.then((res) => {
						this.meet = res.data.meeting;
						this.confirmedMeeting = res.data.scheduledMeeting;
						this.selectedLocation = res.data.scheduledMeeting.location;
						this.confirmedTime = this.confirmedMeeting.confirmedTime;
						this.loadFont(this.meet.pageLayout.fontFamily);
					})
					.catch((err) => {
						console.log(err);
						this.$router.push('/NotFound');
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			loadRecaptcha: function () {
				let s = document.createElement('script');
				s.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECATPCHA_SITE_KEY}`);
				document.body.appendChild(s);
			},

			loadStripeJS: function () {
				let scriptEle = document.createElement('script');

				scriptEle.setAttribute('src', 'https://js.stripe.com/v3/');
				scriptEle.setAttribute('type', 'text/javascript');
				scriptEle.setAttribute('async', 'false');
				document.body.appendChild(scriptEle);

				scriptEle.addEventListener('load', () => {
					this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {
						stripeAccount: this.meet.stripeAccountId,
					});
				});

				scriptEle.addEventListener('error', (ev) => {
					console.log('Error on loading file', ev);
				});
			},

			getMeetingInfo: function () {
				this.$store.commit('startLoading');

				this.meetingService
					.getMeetingInfo(
						this.accountKey,
						this.meetingId,
						this.start.toISODate(),
						this.end.toISODate(),
						this.conflictCheck
					)
					.then((res) => {
						this.meet = res.data;
						this.selectedLocation = this.meet.locations[0];
						this.loadFont(this.meet.pageLayout.fontFamily);
						if (this.meet.paymentEnabled && this.meet.stripeAccountId) {
							this.loadStripeJS();
						}
						if (this.meet.reCaptchaEnabled) {
							this.loadRecaptcha();
						}
					})
					.catch((err) => {
						console.log(err);
						this.$router.push('/NotFound');
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			setStripePaymentIntent: function (stripePaymentIntent) {
				this.stripePaymentIntent = stripePaymentIntent;
				localStorage.setItem(this.paymentIntentKey, stripePaymentIntent.id);
			},

			loadFont: function (fontName) {
				let font = this.customFonts.find((f) => f.fontName === fontName);
				if (font) {
					let newFont = new FontFace(font.fontName, `url(${font.fontUrl})`);
					newFont.load().then(() => {
						document.fonts.add(newFont);
						this.setFont();
					});
				} else {
					WebFontLoader.load({
						google: {
							families: [`${fontName}:200,300,400,500,600`],
						},
						active: this.setFont,
					});
				}
			},

			setFont: function () {
				this.font = this.meet.pageLayout.fontFamily;
			},

			handleError: function (err) {
				this.showError = true;
				if (err && err.response && err.response.data) {
					this.errorText = err.response.data.message;
				} else {
					this.errorText = 'Unknown error';
				}
				this.$refs.MeetingForm.enableButton();
			},

			unConfirmTime: function () {
				this.confirmedTime = null;
			},

			confirmTime: function (meetingSlot) {
				this.confirmedTime = meetingSlot;

				if (this.action === 'change') {
					this.handleRescheduleRequest();
				} else if (this.defaultContact && !this.meet.discoveryTemplate && !this.meet.paymentEnabled) {
					this.$refs.MeetingForm.scheduleMeeting();
				} else {
					this.$refs.MeetingForm.init();
				}
			},

			handleRescheduleRequest: function () {
				this.$store.commit('startLoading');
				this.meetingService
					.rescheduleMeeting(this.id, this.confirmedTime)
					.then((res) => {
						window.parent.postMessage('MEETING_RESCHEDULED', '*');
						if (this.meet.defaultConfirmationPage || !this.meet.confirmationRedirect) {
							this.confirmedMeeting = res.data;
							this.confirmationPage = true;
						} else {
							this.redirectToConfirmationPage(res);
						}
					})
					.catch((err) => this.handleError(err))
					.finally(() => this.$store.commit('stopLoading'));
			},

			async handleFormResult(formData) {
				this.$store.commit('startLoading');

				let files = formData.files;
				delete formData.files;

				let scheduleRequest = {
					accountId: this.meet.account.accountId,
					meetingId: this.meet.id,
					meetingName: this.meet.name,
					confirmedTime: this.confirmedTime,
					location: this.selectedLocation,
					scheduledTimezone: Settings.defaultZoneName,
					scheduledLocale: this.$store.state.locale,
					formData: formData,
					opportunityId: this.opportunityId,
					stripePaymentIntent: this.stripePaymentIntent,
				};

				if (this.meet.reCaptchaEnabled) {
					window.grecaptcha.ready(() => {
						window.grecaptcha
							.execute(process.env.VUE_APP_RECATPCHA_SITE_KEY, { action: 'submit' })
							.then((reCaptchaToken) => {
								this.scheduleMeeting(scheduleRequest, files, reCaptchaToken);
							});
					});
				} else {
					this.scheduleMeeting(scheduleRequest, files, null);
				}
			},

			scheduleMeeting: function (scheduleRequest, files, reCaptchaToken) {
				this.meetingService
					.scheduleMeeting(scheduleRequest, files, reCaptchaToken, this.conflictCheck)
					.then((res) => {
						localStorage.removeItem(this.paymentIntentKey);
						try {
							parent.postMessage('MEETING_SCHEDULED', '*');
							parent.postMessage('[Result]' + Base64.encode(JSON.stringify(scheduleRequest)), '*');
						} catch (err) {
							console.log(err);
						}

						if (this.meet.defaultConfirmationPage || !this.meet.confirmationRedirect) {
							this.confirmedMeeting = res.data;
							this.confirmationPage = true;
						} else {
							this.redirectToConfirmationPage(res);
						}
					})
					.catch((err) => this.handleError(err))
					.finally(() => this.$store.commit('stopLoading'));
			},

			redirectToConfirmationPage: function (res) {
				let url = this.meet.confirmationRedirect;

				if (this.meet.appendInfoToRedirect) {
					try {
						if (!url.includes('?')) {
							url = url + '?';
						} else {
							url = url + '&';
						}

						url = url + 'id=' + encodeURIComponent(res.data.id) + '&';
						url = url + 'start=' + encodeURIComponent(res.data.confirmedTime.start) + '&';
						url =
							url +
							'duration=' +
							encodeURIComponent(this.meet.length.duration + ' ' + this.meet.length.timeUnit.toLowerCase()) +
							'&';
						url = url + 'location=' + encodeURIComponent(this.selectedLocation.type) + '&';
						url = url + 'email=' + encodeURIComponent(res.data.formData.email) + '&';
						url =
							url +
							'name=' +
							encodeURIComponent(res.data.formData.firstName + ' ' + res.data.formData.lastName) +
							'&';
					} catch (err) {
						console.log(err);
					}
				}

				if (this.inFrame || this.inPortal) {
					window.parent.postMessage('[Redirect]' + url, '*');
				} else {
					window.location.href = url;
				}
			},

			checkNull: function (val) {
				return val ? val : '';
			},

			timeUnitTitleCase: function (str) {
				return str.substring(0, 1) + str.substring(1).toLowerCase();
			},

			locationDesc: function () {
				switch (this.selectedLocation.type) {
					case 'Google':
						return this.$t('meetings.info.google-meeting');
					case 'Microsoft':
						return this.$t('meetings.info.microsoft-meeting');
					case 'Zoom':
						return this.$t('meetings.info.zoom-meeting');
					case 'Sessions':
						return this.$t('meetings.info.sessions-meeting');
					case 'PhoneIn':
						return this.$t('meetings.info.phone-in-meeting');
					case 'PhoneOut':
						return this.$t('meetings.info.phone-out-meeting');
					case 'InPerson':
						return this.$t('meetings.info.in-person-meeting');
					case 'Custom':
						return this.selectedLocation.custom;
					default:
						return this.$t('meetings.info.meeting-information');
				}
			},

			currency: function (amount = 0, cents = true, currencySign = true, currency = 'usd') {
				if (!amount) {
					amount = 0;
				}

				amount = amount.toString().replace(/[^-0-9.]/g, '');
				if (isNaN(amount)) amount = 0;
				const hasDecimals = amount - Math.floor(amount) !== 0;
				let minimumFractionDigits = 2;
				if (!cents || !hasDecimals) {
					minimumFractionDigits = 0;
				}
				if (currencySign) {
					return new Intl.NumberFormat(navigator.languages[0], {
						style: 'currency',
						currency: currency,
						currencySign: 'accounting',
						maximumFractionDigits: minimumFractionDigits,
					}).format(amount);
				} else {
					return new Intl.NumberFormat(navigator.languages[0], {
						style: 'currency',
						currency: currency,
						maximumFractionDigits: minimumFractionDigits,
					})
						.format(amount)
						.replace(/[^0-9.,]/g, '');
				}
			},
		},

		computed: {
			tokenMap: function () {
				let result = new Map();

				if (this.meet) {
					let amount = 0;
					let currency = 'USD';

					if (this.meet.requiredPayment && this.meet.paymentEnabled) {
						amount = this.meet.requiredPayment.amount;
						currency = this.meet.requiredPayment.currency;
					}

					result.set('Payment.Amount', this.currency(amount, true, true, currency));
					result.set('Meeting.Name', this.checkNull(this.meet.name));
					result.set('Meeting.Duration', this.checkNull(this.meet.length.duration));
					result.set('Meeting.Location', this.checkNull(this.selectedLocation.type));
					result.set('Meeting.TimeUnit', this.checkNull(this.timeUnitTitleCase(this.meet.length.timeUnit)));
					result.set('Meeting.ScheduledTime', this.checkNull(this.formatConfirmedTime));
					result.set('Meeting.AccountName', this.checkNull(this.meet.account.accountName));
					result.set('Meeting.OwnerName', this.checkNull(this.meet.ownerName));
					result.set('Meeting.LocationInfo', this.checkNull(this.locationDesc));
					result.set('Meeting.TimeZone', this.checkNull(Settings.defaultZoneName));
					result.set('Meeting.Description', this.checkNull(this.meet.description));
					result.set(
						'Icons.Clock',
						`<span><i class="v-icon notranslate material-icons" aria-hidden="true">schedule</i></span>`
					);
					result.set(
						'Icons.Mic',
						`<span><i class="v-icon notranslate material-icons" aria-hidden="true">mic</i></span>`
					);
					result.set(
						'Icons.Event',
						this.confirmedTime
							? `<span><i class="v-icon notranslate material-icons" aria-hidden="true">event</i></span>`
							: ''
					);
					result.set(
						'Icons.Public',
						`<span><i class="v-icon notranslate material-icons" aria-hidden="true">public</i></span>`
					);
					result.set(
						'Icons.Info',
						`<span><i class="v-icon notranslate material-icons" aria-hidden="true">info</i></span>`
					);
				}

				return result;
			},

			formatConfirmedTime: function () {
				if (this.confirmedTime) {
					let s = DateTime.fromISO(this.confirmedTime.start);
					let e = DateTime.fromISO(this.confirmedTime.end);

					return (
						s.toLocaleString(DateTime.TIME_SIMPLE) +
						' - ' +
						e.toLocaleString(DateTime.TIME_SIMPLE) +
						', ' +
						s.toLocaleString({ weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })
					);
				} else {
					return null;
				}
			},

			opportunityId: function () {
				return this.$route.query.opportunityId;
			},

			id: function () {
				return this.$route.query.id;
			},

			inFrame: function () {
				return this.$route.query.inFrame ? true : false;
			},

			inPortal: function () {
				return this.$route.query.inPortal ? true : false;
			},

			preview: function () {
				return this.$route.query.preview ? true : false;
			},

			conflictCheck: function () {
				if (this.$route.query.conflictCheck && this.$route.query.conflictCheck === 'false') {
					return false;
				} else {
					return true;
				}
			},

			action: function () {
				return this.$route.query.action;
			},
		},
	};
</script>

<style scoped lang="scss">
	.main-wrapper {
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 2px;
		max-width: 900px;
		margin: 20px;
	}

	.main-wrapper-in-frame {
		//border: 1px solid var(--v-gray_50-base);
		//border-radius: 2px;
		//margin:5px;
	}
</style>
