<template>
	<v-container>
		<v-row dense v-if="meet.pageLayout.showLogo">
			<v-col cols="12" align="left" class="">
				<img alt="Business logo" :src="meet.account.accountLogo" style="max-width: 80%; max-height: 150px">
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" align="left">
				<div style="line-height: 1.5;" v-html="tokenizedText"></div>
			</v-col>
		</v-row>
		<v-row dense v-if="meet.locations.length > 1 && !isReschedule">
			<v-col cols="12">
					<v-radio-group v-model="ourLocation" hide-details dense @change="$emit('select-location',ourLocation)" class="mt-n4">
						<v-radio  v-for="(location,index) in meet.locations" :key="index" :value="location">
							<template v-slot:label>
								<div class="row-format align-center gap-2">
									<v-icon class="material-symbols-rounded">{{ getIcon(location) }}</v-icon>
									<div class="brand-medium">{{getLabel(location)}}</div>
								</div>
							</template>
						</v-radio>
					</v-radio-group>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { Settings } from 'luxon';
  import timezones from '@/assets/data/timezones.json';

	export default {
		name: 'MeetingInfo',

		props: ['meet', 'confirmedTime','action', 'tokenMap','selectedLocation','isReschedule'],

		components: {},

		data: function () {
			return {
				Settings:Settings,
        timezones: timezones,
				ourLocation: this.selectedLocation
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			getLabel: function(location){
				switch(location.type){
					case 'Google':{return 'Google meet'}
					case 'Microsoft':{return 'Microsoft teams'}
					case 'Zoom':{return 'Zoom'}
					case 'PhoneIn':{return `Call ${location.phone}`}
					case 'PhoneOut':{return 'I\'ll call you'}
					case 'InPerson':{return `In person`}
					case 'Custom':{return location.custom}
				}
			},

			getIcon: function(location){
				switch(location.type){
					case 'Google':{return '$googleCalendar'}
					case 'Microsoft':{return '$microsoft'}
					case 'Zoom':{return '$zoomIcon'}
					case 'PhoneIn':{return `$phoneInTalk`}
					case 'PhoneOut':{return '$phoneOut'}
					case 'InPerson':{return `$map`}
					case 'Custom':{return `info`}
				}
			},
    },

		computed: {
			tokenizedText: function() {
				let result = this.meet.scheduleMeeting.schedulerInfo;
				this.tokenMap.forEach((value, key) => (result = result.replaceAll('{{' + key + '}}', value)));
				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
