export default {
	iconfont: 'md',

	values: {
		/** Next Gen Icons **/
		clients: {
			component: 'h-icon2',
			props: { name: 'clients' },
		},
		projects: {
			component: 'h-icon2',
			props: { name: 'projects' },
		},
		calendar: {
			component: 'h-icon2',
			props: { name: 'calendar' },
		},
		accounting: {
			component: 'h-icon2',
			props: { name: 'accounting' },
		},
		reports: {
			component: 'h-icon2',
			props: { name: 'reports' },
		},
		search: {
			component: 'h-icon2',
			props: { name: 'search' },
		},
		todo: {
			component: 'h-icon2',
			props: { name: 'todos' },
		},
		notes: {
			component: 'h-icon2',
			props: { name: 'notes' },
		},
		notifications: {
			component: 'h-icon2',
			props: { name: 'notifications' },
		},
		invoices: {
			component: 'h-icon2',
			props: { name: 'invoices' },
		},
		edit: {
			component: 'h-icon2',
			props: { name: 'edit' },
		},
		ribbon: {
			component: 'h-icon2',
			props: { name: 'ribbon' },
		},

		/** Legacy Icons **/
		partyPopper: {
			component: 'h-icon',
			props: { name: 'party-popper' },
		},
		party: {
			component: 'h-icon',
			props: { name: 'party' },
		},
		logout: {
			component: 'h-icon',
			props: { name: 'logout' },
		},
		payment: {
			component: 'h-icon',
			props: {name: 'payment'},
		},
		wavingHand: {
			component: 'h-icon',
			props: { name: 'waving-hand' },
		},
		rocket: {
			component: 'h-icon',
			props: { name: 'rocket' },
		},
		svgFile: {
			component: 'h-icon',
			props: { name: 'svgFile' },
		},
		csvFile: {
			component: 'h-icon',
			props: { name: 'csvFile' },
		},
		pdfFile: {
			component: 'h-icon',
			props: { name: 'pdfFile' },
		},
		pptFile: {
			component: 'h-icon',
			props: { name: 'pptFile' },
		},
		zipFile: {
			component: 'h-icon',
			props: { name: 'zipFile' },
		},
		unknownFile: {
			component: 'h-icon',
			props: { name: 'unknownFile' },
		},
		safe: {
			component: 'h-icon',
			props: { name: 'safe' },
		},
		timetracking: {
			component: 'h-icon',
			props: { name: 'timetracking' },
		},
		proposals: {
			component: 'h-icon',
			props: { name: 'proposals' },
		},
		bizdev: {
			component: 'h-icon',
			props: { name: 'bizdev' },
		},
		expenses: {
			component: 'h-icon',
			props: { name: 'expenses' },
		},
		team: {
			component: 'h-icon',
			props: { name: 'team' },
		},
		apple: {
			component: 'h-icon',
			props: { name: 'apple' },
		},
		google: {
			component: 'h-icon',
			props: { name: 'google' },
		},
		paypal: {
			component: 'h-icon',
			props: { name: 'paypal' },
		},
		stripe: {
			component: 'h-icon',
			props: { name: 'stripe' },
		},
		plaid: {
			component: 'h-icon',
			props: { name: 'plaid' },
		},
		hummingbird: {
			component: 'h-icon',
			props: { name: 'hummingbird' },
		},
		drag: {
			component: 'h-icon',
			props: { name: 'drag-indicator' },
		},
		menu: {
			component: 'h-icon',
			props: { name: 'menu' },
		},
		alert: {
			component: 'h-icon',
			props: { name: 'alert' },
		},
		map: {
			component: 'h-icon',
			props: { name: 'map' },
		},
		openInNew: {
			component: 'h-icon',
			props: { name: 'open-in-new' },
		},
		phoneInTalk: {
			component: 'h-icon',
			props: { name: 'phone-in-talk' },
		},
		googleCalendar: {
			component: 'h-icon',
			props: { name: 'googleCalendar' },
		},
		microsoft: {
			component: 'h-icon',
			props: { name: 'microsoft' },
		},
		zoomIcon: {
			component: 'h-icon',
			props: { name: 'zoom-icon' },
		},
		phoneOut: {
			component: 'h-icon',
			props: { name: 'phone-out' },
		},
		stopwatch: {
			component: 'h-icon',
			props: { name: 'stopwatch' },
		},
		vendor: {
			component: 'h-icon',
			props: { name: 'vendor', twoColor: true },
		},
		onBoarding: {
			component: 'h-icon',
			props: { name: 'on-boarding' },
		},
		help: {
			component: 'h-icon',
			props: { name: 'help' },
		},
		comment: {
			component: 'h-icon',
			props: { name: 'comment' },
		},
		remove: {
			component: 'h-icon',
			props: { name: 'remove' },
		},
		download: {
			component: 'h-icon',
			props: { name: 'download' },
		},
		actions: {
			component: 'h-icon',
			props: { name: 'bolt' },
		},
		contacts: {
			component: 'h-icon',
			props: { name: 'contacts' },
		},
		forms: {
			component: 'h-icon',
			props: { name: 'forms' },
		},
		contact: {
			component: 'h-icon',
			props: { name: 'contact' },
		},
		deliverables: {
			component: 'h-icon',
			props: { name: 'check' },
		},
		checkOrig: {
			component: 'h-icon',
			props: { name: 'check' },
		},
		check: {
			component: 'h-icon',
			props: { name: 'small-check' },
		},
		clock: {
			component: 'h-icon',
			props: { name: 'clock' },
		},
		deliverableBox: {
			component: 'h-icon',
			props: { name: 'deliverable-box', twoColor: true },
		},
		ideaLightBulb: {
			component: 'h-icon',
			props: { name: 'idea-light-bulb', twoColor: true },
		},
		integrationsPlug: {
			component: 'h-icon',
			props: { name: 'integrations-plug', twoColor: true },
		},
		file: {
			component: 'h-icon',
			props: { name: 'file2' },
		},
		calculator: {
			component: 'h-icon',
			props: { name: 'calculator' },
		},
		wallet: {
			component: 'h-icon',
			props: { name: 'wallet' },
		},
		filter: {
			component: 'h-icon',
			props: { name: 'filter-small' },
		},
		// edit: {
		// 	component: 'h-icon',
		// 	props: { name: 'edit' },
		// },
		delete: {
			component: 'h-icon',
			props: { name: 'delete' },
		},
		moreHorizontal: {
			component: 'h-icon',
			props: { name: 'more-hor' },
		},
		moreVertical: {
			component: 'h-icon',
			props: { name: 'more-ver' },
		},
		plus: {
			component: 'h-icon',
			props: { name: 'plus' },
		},
		plusSmall: {
			component: 'h-icon',
			props: { name: 'plus-small' },
		},
		sort: {
			component: 'h-icon',
			props: { name: 'sort' },
		},
		email: {
			component: 'h-icon',
			props: { name: 'email' },
		},
		photo: {
			component: 'h-icon',
			props: { name: 'photo' },
		},
		phone: {
			component: 'h-icon',
			props: { name: 'phone' },
		},
		money: {
			component: 'h-icon',
			props: { name: 'money' },
		},
		calendarMono: {
			component: 'h-icon',
			props: { name: 'calendar-mono' },
		},
		alignLeft: {
			component: 'h-icon',
			props: { name: 'align-left' },
		},
		alignRight: {
			component: 'h-icon',
			props: { name: 'align-right' },
		},
		alignCenter: {
			component: 'h-icon',
			props: { name: 'align-center' },
		},
		alignJustify: {
			component: 'h-icon',
			props: { name: 'align-justify' },
		},
		arrowUp: {
			component: 'h-icon',
			props: { name: 'arrow-up' },
		},
		arrowDown: {
			component: 'h-icon',
			props: { name: 'arrow-down' },
		},
		arrowLeft: {
			component: 'h-icon',
			props: { name: 'arrow-left' },
		},
		arrowRight: {
			component: 'h-icon',
			props: { name: 'arrow-right' },
		},
		arrowCurlyLeft: {
			component: 'h-icon',
			props: { name: 'arrow-curly-left' },
		},
		arrowCurlyRight: {
			component: 'h-icon',
			props: { name: 'arrow-curly-right' },
		},
		discovery: {
			component: 'h-icon',
			props: { name: 'list-bullet' },
		},
		listBullet: {
			component: 'h-icon',
			props: { name: 'list-bullet' },
		},
		graph: {
			component: 'h-icon',
			props: { name: 'graph' },
		},
		book: {
			component: 'h-icon',
			props: { name: 'book' },
		},

		upload: {
			component: 'h-icon',
			props: { name: 'upload' },
		},
		triangleUp: {
			component: 'h-icon',
			props: { name: 'triangle-up' },
		},
		triangleDown: {
			component: 'h-icon',
			props: { name: 'triangle-down' },
		},
		triangleLeft: {
			component: 'h-icon',
			props: { name: 'triangle-left' },
		},
		triangleRight: {
			component: 'h-icon',
			props: { name: 'triangle-right' },
		},
		success: {
			component: 'h-icon',
			props: { name: 'success' },
		},
		visibility: {
			component: 'h-icon',
			props: { name: 'visibility' },
		},
		visibilityOff: {
			component: 'h-icon',
			props: { name: 'visibility-off' },
		},
		chevronUp: {
			component: 'h-icon',
			props: { name: 'chevron-up' },
		},
		chevronDown: {
			component: 'h-icon',
			props: { name: 'chevron-down' },
		},
		chevronRight: {
			component: 'h-icon',
			props: { name: 'chevron-right' },
		},
		chevronLeft: {
			component: 'h-icon',
			props: { name: 'chevron-left' },
		},
		imageLeft: {
			component: 'h-icon',
			props: { name: 'image-left' },
		},
		imageRight: {
			component: 'h-icon',
			props: { name: 'image-right' },
		},
		close: {
			component: 'h-icon',
			props: { name: 'close' },
		},
		alarm: {
			component: 'h-icon',
			props: { name: 'alarm', twoColor: true },
		},
		cashInHand: {
			component: 'h-icon',
			props: { name: 'cashInHand', twoColor: true },
		},
		magnet: {
			component: 'h-icon',
			props: { name: 'magnet', twoColor: true },
		},
		apps: {
			component: 'h-icon',
			props: { name: 'apps' },
		},
		attachment: {
			component: 'h-icon',
			props: { name: 'attachment', twoColor: true },
		},
		cards: {
			component: 'h-icon',
			props: { name: 'cards', tooltip: 'Card view' },
		},
		handshake: {
			component: 'h-icon',
			props: { name: 'handshakeTwoColor', twoColor: true },
		},
		image: {
			component: 'h-icon',
			props: { name: 'image', twoColor: true },
		},
		imageAndText: {
			component: 'h-icon',
			props: { name: 'imageAndText', twoColor: true },
		},
		atSymbol: {
			component: 'h-icon',
			props: { name: 'atSymbol' },
		},
		celebration: {
			component: 'h-icon',
			props: { name: 'celebration', twoColor: true },
		},
		mail: {
			component: 'h-icon',
			props: { name: 'mail' },
		},
		mailOpen: {
			component: 'h-icon',
			props: { name: 'mailOpen', twoColor: true },
		},
		// ribbon: {
		// 	component: 'h-icon',
		// 	props: { name: 'ribbon', twoColor: true },
		// },
		table: {
			component: 'h-icon',
			props: { name: 'table', tooltip: 'List view' },
		},
		text: {
			component: 'h-icon',
			props: { name: 'text', twoColor: true },
		},
		user: {
			component: 'h-icon',
			props: { name: 'user' },
		},
		simpleUser: {
			component: 'h-icon',
			props: { name: 'simple-user' },
		},
		share: {
			component: 'h-icon',
			props: { name: 'share' },
		},
		kanban: {
			component: 'h-icon',
			props: { name: 'kanban' },
		},
		searchBolt: {
			component: 'h-icon',
			props: { name: 'searchBolt' } /* No twoColor prop for top-bar icons. It breaks the hover */,
		},
		chat: {
			component: 'h-icon',
			props: { name: 'chat' },
		},
		info: {
			component: 'h-icon',
			props: { name: 'info' },
		},
		closeSmall: {
			component: 'h-icon',
			props: { name: 'close-small' },
		},
		nearMe: {
			component: 'h-icon',
			props: { name: 'near-me' },
		},
		lock: {
			component: 'h-icon',
			props: { name: 'lock' },
		},
		subtract: {
			component: 'h-icon',
			props: { name: 'subtract' },
		},
		dragSecondary: {
			component: 'h-icon',
			props: { name: 'drag-secondary' },
		},
		duplicate: {
			component: 'h-icon',
			props: { name: 'duplicate' },
		},
		signature: {
			component: 'h-icon',
			props: { name: 'signature', twoColor: true },
		},
		box: {
			component: 'h-icon',
			props: { name: 'box', twoColor: true },
		},
		questionMark: {
			component: 'h-icon',
			props: { name: 'question-mark', twoColor: true },
		},
		multipleAnswers: {
			component: 'h-icon',
			props: { name: 'multiple-answers', twoColor: true },
		},
		bulb: {
			component: 'h-icon',
			props: { name: 'bulb' },
		},
		playCircle: {
			component: 'h-icon',
			props: { name: 'play-circle' },
		},
		play: {
			component: 'h-icon',
			props: { name: 'play' },
		},
		stopCircle: {
			component: 'h-icon',
			props: { name: 'stop-circle' },
		},
		stop: {
			component: 'h-icon',
			props: { name: 'stop' },
		},
		users: {
			component: 'h-icon',
			props: { name: 'users' },
		},
		time: {
			component: 'h-icon',
			props: { name: 'time' },
		},
		settings: {
			component: 'h-icon',
			props: { name: 'settings' },
		},
		paperAirplane: {
			component: 'h-icon',
			props: { name: 'paper-airplane' },
		},
		copyToClipboard: {
			component: 'h-icon',
			props: { name: 'copy-to-clipboard' },
		},
		plusCircle: {
			component: 'h-icon',
			props: { name: 'plusCircle' },
		},
		comment3: {
			component: 'h-icon',
			props: { name: 'comment3' },
		},
		paperClip: {
			component: 'h-icon',
			props: { name: 'paperClip' },
		},
	},
};
